import gql from 'graphql-tag';

export const CREATE_NEW_TEMPLATE = gql`
	mutation Mutation($input: CreateActivityTemplateInput!) {
		createActivityTemplate(input: $input) {
			id
		}
	}
`;

export const UPDATE_TEMPLATE = gql`
	mutation Mutation($input: UpdateActivityTemplateInput!) {
		updateActivityTemplate(input: $input) {
			id
			layout_id
			layout {
				current_version {
					schema
				}
				description
				name
				reference_name
				settings
				status_id
				type_id
			}
			initiator_id
			initiator {
				current_version {
					schema
				}
				description
				name
				reference_name
				settings
				status_id
				type_id
			}
			closer_id
			closer {
				current_version {
					schema
				}
				description
				name
				reference_name
				settings
				status_id
				type_id
			}
			workflow_id
			publisher {
				last_name
				middle_name
				first_name
				id
			}
			archiver {
				last_name
				middle_name
				first_name
				id
			}
			creator {
				last_name
				middle_name
				first_name
				id
			}
			updater {
				last_name
				middle_name
				first_name
				id
			}
			description
			name
			status
			type
			settings
			variables
		}
	}
`;
