<template>
	<Menu as="span" class="relative inline-block text-left">
		<div>
			<MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
				Actions
				<span class="sr-only">Open actions</span>
				<FontAwesomeIcon :icon="faCaretDown" aria-hidden="true" class="h-5 w-5 text-primary-600" />
			</MenuButton>
		</div>

		<transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
				<MenuItem v-for="item in props.items" :key="item.name" v-slot="{ active }">
					<span role="button" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'z-50 block px-4 py-2 text-sm select-none first:rounded-t-md last:rounded-b-md']" @click="item.onClick">
						{{ item.name }}
					</span>
				</MenuItem>
			</MenuItems>
		</transition>
	</Menu>
</template>

<script setup lang="ts">
	import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
	import { computed, defineProps } from 'vue';

	export interface DropdownMenuItem {
		name: string;
		primary?: boolean;
		// eslint-disable-next-line @typescript-eslint/ban-types
		onClick: Function | undefined;
	}

	interface Props {
		items: DropdownMenuItem[];
	}

	const props = defineProps<Props>();

	const primaryItem = computed(() => {
		return props.items.find((item: DropdownMenuItem) => item.primary === true) || null;
	});
</script>
