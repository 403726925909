<template>
	<div class="transition-all duration-75 transform -mt-2 py-4">
		<AddNewInteraction :open="showCreateInteractionForm" @closing="showCreateInteractionForm = false" @closed="showCreateInteractionButton = true"></AddNewInteraction>
		<div class="px-4 pb-6">
			<div class="pt-2 pb-2 border-b border-gray-200 sm:flex sm:items-end sm:justify-between mb-5">
				<div class="flex items-end ml-0.5 -mb-1 text-sm text-gray-500 h-full">{{ ucword(activityStore.interactionLabelPlural) }} are ordered by newest first.</div>
				<TransitionRoot
					v-if="activityStore.activityStatus == ActivityStatus.OPEN"
					:show="showCreateInteractionButton"
					as="div"
					class="mt-3 sm:mt-0 sm:ml-4"
					enter="transition-opacity duration-75"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="transition-opacity duration-75"
					leave-from="opacity-100"
					leave-to="opacity-0"
					@after-leave="showCreateInteractionForm = true"
				>
					<Button :label="'Create new ' + activityStore.interactionLabelSingular.toLowerCase()" color="primary" @click="showCreateInteractionButton = false" />
				</TransitionRoot>
			</div>

			<div class="flex flex-col space-y-3">
				<ActivityInteraction v-if="activityStore.creatingNewInteraction" :interaction="{} as ActivityInteractionType" :skeleton="true" />
				<ActivityInteraction v-for="interaction in activityStore.activityInteractions.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? -1 : 1))" :key="interaction.id" :interaction="interaction" :skeleton="false" />
				<div v-if="activityStore.activityInteractions.length === 0" class="mx-auto">
					<h1 class="mt-6 font-medium text-2xl text-gray-600">They are no {{ activityStore.interactionLabelPlural.toLowerCase() }} yet.</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import AddNewInteraction from '@modules/activities/partials/AddNewInteraction.vue';
	import { useActivityStore } from '@modules/activities/store';
	import ActivityInteraction from '@modules/activities/partials/ActivityInteraction.vue';
	import { ActivityInteraction as ActivityInteractionType } from '@/types/graphql';
	import { TransitionRoot } from '@headlessui/vue';
	import { ref } from 'vue';
	import { ucword } from '@utils/helpers.js';
	import Button from '@components/Button.vue';
	import { ActivityStatus } from '@/types/activity';

	const activityStore = useActivityStore();

	const showCreateInteractionForm = ref(false);
	const showCreateInteractionButton = ref(true);
</script>

<style lang="scss"></style>
