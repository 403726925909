<template>
	<div
		class="px-4 py-5 sm:pl-6 sm:pr-4 flex flex-col rounded-lg shadow relative"
		:class="{
			'bg-white': interaction.visibility === ActivityInteractionVisibility.PUBLIC || interaction.visibility === ActivityInteractionVisibility.AUDIT,
			'bg-primary-50/30': interaction.visibility === ActivityInteractionVisibility.PRIVATE,
			'opacity-25': interaction.visibility === ActivityInteractionVisibility.AUDIT,
		}"
	>
		<div class="flex space-x-4">
			<div class="flex-shrink-0">
				<div v-if="skeleton" class="w-10 bg-gray-300 h-12 rounded-full shimmer-animate" />

				<div v-else class="h-10 w-10 rounded-full">
					<UserAvatar :first-name="interaction?.creator?.first_name?.toString() ?? ''" :last-name="interaction?.creator?.last_name?.toString() ?? ''" :size="10" />
				</div>
			</div>
			<div class="min-w-0 flex-1">
				<template v-if="skeleton">
					<div>
						<div class="w-48 bg-gray-300 h-6 rounded-md shimmer-animate" />
					</div>
					<div>
						<div class="w-48 mt-1 bg-gray-300 h-6 rounded-md shimmer-animate" />
					</div>
					<div class="mt-3 text-md text-gray-900">
						<div class="w-full h-10 bg-gray-300 rounded-md shimmer-animate" />
					</div>
				</template>
				<template v-else>
					<div>
						<p class="text-sm font-medium text-gray-900">
							<span>{{ interaction.creator.first_name }} {{ interaction.creator.last_name }}</span>
						</p>
					</div>
					<div>
						<p class="text-sm text-gray-500">
							<span :title="getLocalizedDate(interaction.created_at).format('ddd, MMM D, YYYY HH:mm:ss ZZ (zzz)')">{{ timeSinceInteraction }}</span>
						</p>
					</div>
					<div class="my-3 text-md text-gray-900">
						<div class="content ProseMirror" v-html="interactionContent()"></div>
					</div>
					<div v-if="interaction.attachments && interaction.attachments.length > 0" class="mt-1 text-sm text-gray-700 w-full sm:w-1/2">
						<dt class="text-xs font-medium text-gray-500">Attachments</dt>
						<dd class="mt-1 text-sm text-gray-900">
							<ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
								<li v-for="attachment in interaction.attachments" :key="attachment.id" class="pl-3 pr-4 py-3 flex flex-row items-center text-sm">
									<div class="flex items-center w-1/3">
										<FontAwesomeIcon :icon="faPaperclip" aria-hidden="true" class="flex-shrink-0 h-5 w-5 text-gray-400" />
										<span class="ml-2 flex-1 truncate"> {{ attachment.name }} </span>
									</div>
									<div class="flex items-center">{{ (attachment.size / 1024 / 1024).toFixed(2) }} MB</div>
									<div class="ml-4 flex flex-1 justify-end w-full">
										<a href="#" class="font-medium text-primary-600 hover:text-primary-500 flex justify-center items-center">
											Download
											<FontAwesomeIcon :icon="faSave" aria-hidden="true" class="ml-2 flex-shrink-0 h-5 w-5"
										/></a>
									</div>
								</li>
							</ul>
						</dd>
					</div>
				</template>
			</div>
		</div>
		<div v-if="!skeleton" class="absolute right-2 -mt-1.5 flex flex-col justify-start items-start flex-shrink">
			<div class="flex flex-row items-center justify-end space-x-0.5">
				<div v-for="tag in interaction.meta" v-if="interaction.meta && interaction.meta.length > 0" :key="tag.id" class="flex flex-row justify-end items-center space-x-2">
					<div v-if="tag.type === 'after_hours'" class="flex justify-center items-center rounded-xl p-1.5 bg-danger-500 text-white" title="interaction has been marked as after hours work">
						<FontAwesomeIcon :icon="faHourglassEmpty" aria-hidden="true" class="h-4 w-4" />
					</div>
					<div v-else-if="tag.type === 'time_spent'" class="flex justify-center items-center rounded-xl px-1 py-0.5 bg-secondary-500 text-white" title="Work time has has been logged against this interaction">{{ tag.value }}m</div>
				</div>
				<div v-if="interaction.visibility === ActivityInteractionVisibility.PRIVATE" class="flex justify-center items-center rounded-2xl p-1.5 bg-danger-500 text-white" title="Visibility of this interaction is PRIVATE">
					<FontAwesomeIcon :icon="faEyeSlash" aria-hidden="true" class="h-5 w-5" />
				</div>
				<div v-if="interaction.visibility === ActivityInteractionVisibility.AUDIT" class="flex justify-center items-center rounded-2xl p-1.5 bg-black text-white" title="Visibility of this interaction is DELETED">
					<FontAwesomeIcon :icon="faTrashCan" aria-hidden="true" class="h-5 w-5" />
				</div>
				<span style="width: 5px"></span>
				<Button v-if="canEdit" classes="p-1 rounded-full flex items-center justify-center text-secondary-500 hover:text-secondary-600 focus:outline-none focus:ring-2 focus:ring-primary-600"
					><span class="sr-only">Edit {{ activityStore.interactionLabelSingular.toLowerCase() }}</span> <FontAwesomeIcon :icon="faEdit" aria-hidden="true" class="h-5 w-5"
				/></Button>
				<Button
					v-if="activityStore.activityStatus == ActivityStatus.OPEN"
					classes="p-1 rounded-full flex items-center justify-center text-danger-500 hover:text-danger-700 focus:outline-none focus:ring-2 focus:ring-primary-600"
					@click="confirmDeleteInteraction"
					><span class="sr-only">Delete {{ activityStore.interactionLabelSingular.toLowerCase() }}</span> <FontAwesomeIcon :icon="faTrashCan" aria-hidden="true" class="h-5 w-5"
				/></Button>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { computed, defineProps } from 'vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faEdit, faEyeSlash, faHourglassEmpty, faPaperclip, faSave, faTrashCan } from '@fortawesome/pro-light-svg-icons';
	import UserAvatar from '@modules/user/components/UserAvatar.vue';
	import { getLocalizedDate, getRelativeFormatFrom } from '@utils/useDate.js';
	import { ActivityInteraction } from '@/types/graphql';
	import { useActivityStore } from '@modules/activities/store';
	import xss, { IFilterXSSOptions } from 'xss';
	import xssRules from '@/types/xss';
	import useDialog from '../../../utils/useDialog';
	import { ActivityInteractionVisibility, ActivityStatus } from '@/types/activity';
	import Button from '@components/Button.vue';

	const activityStore = useActivityStore();
	const canEdit = false;

	interface Props {
		interaction: ActivityInteraction;
		skeleton: boolean;
	}

	const interactionContent = () => {
		return xss(props.interaction.content ?? '', { xssRules, css: false } as IFilterXSSOptions);
	};

	const props = defineProps<Props>();

	const timeSinceInteraction = computed<string>(() => {
		return getRelativeFormatFrom(getLocalizedDate(props.interaction.created_at));
	});

	const confirmDeleteInteraction = () => {
		useDialog
			.title('Are you sure you want to do that?')
			.confirm('Continuing with deletion? we can not un-send what notifications have already been sent though!')
			.then(async (result) => {
				if (result.isConfirmed) {
					await activityStore.deleteActivityInteraction(props.interaction.id);
				}
			});
	};
</script>
