<template>
	<AppLayout>
		<template #navigation>
			<HeaderNavigationTabs :tabs="tabs" />
		</template>
		<template #header>
			<PageHeader :title="builderStore.activeTemplate.name" :show-breadcrumbs="true" :base-breadcrumb="baseBreadcrumb" :breadcrumbs="breadcrumbs()">
				<template #actions>
					<button
						class="flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-white hover:bg-gray-100 focus:outline-none"
						type="button"
						@click="builderStore.updateTemplate"
					>
						Save template
						<FontAwesomeIcon :icon="faFloppyDisk" class="h-5 w-5 text-black ml-2" aria-hidden="true" />
					</button>
				</template>
			</PageHeader>
		</template>
		<template #primary>
			<div class="flex flex-col h-full w-full">
				<RouterView />
			</div>
		</template>
	</AppLayout>
</template>

<script lang="ts" setup>
	import AppLayout from '@layouts/AppLayout.vue';
	import { BreadcrumbObject, HeaderNavigationTab } from '@/types/layout';
	import HeaderNavigationTabs from '@components/HeaderNavigationTabs.vue';
	import { onMounted } from 'vue';
	import { useBuilderStore } from '@modules/builder/store';
	import { useRoute } from 'vue-router';
	import PageHeader from '@components/PageHeader.vue';
	import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

	const builderStore = useBuilderStore();
	const route = useRoute();

	const tabs: HeaderNavigationTab[] = [
		{ id: 1, name: 'Settings', to: { name: 'builder.settings' }, selected: true },
		{ id: 2, name: 'Layout', to: { name: 'builder.layout' }, selected: false },
		{ id: 3, name: 'Initiation', to: { name: 'builder.initiation' }, selected: false },
		{ id: 4, name: 'Closer', to: { name: 'builder.closer' }, selected: false },
		{ id: 5, name: 'Workflows', to: { name: 'builder.workflow' }, selected: false },
		{ id: 6, name: 'Forms', to: { name: 'builder.form' }, selected: false },
		{ id: 7, name: 'Variables', to: { name: 'builder.variables' }, selected: false },
	];

	const baseBreadcrumb = {
		route: { name: 'builder.index' },
		label: 'Builder',
	} as BreadcrumbObject;

	const breadcrumbs = () => {
		const breadcrumbs = [];

		for (const matchedRoute of route.matched) {
			breadcrumbs.push({
				route: {
					name: matchedRoute.name,
					params: {
						template_id: route.params.template_id.toString(),
					},
				},
				label: matchedRoute.meta.title ? matchedRoute.meta.title : builderStore.activeTemplate.name,
			});
		}
		return (breadcrumbs as BreadcrumbObject[]).filter((element) => {
			return Object.keys(element).length !== 0;
		});
	};

	onMounted(async () => {
		builderStore.getTemplateById(route.params.template_id.toString());
	});
</script>
