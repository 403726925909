import gql from 'graphql-tag';

export const CREATE_NEW_ACTIVITY = gql`
	mutation Mutation($input: CreateActivityInput!) {
		createActivity(input: $input) {
			id
		}
	}
`;

export const DELETE_ACTIVITY_INTERACTION = gql`
	mutation Mutation($input: DeleteActivityInteractionInput!) {
		deleteActivityInteraction(input: $input)
	}
`;

export const CREATE_NEW_ACTIVITY_INTERACTION = gql`
	mutation Mutation($input: CreateActivityInteractionInput!) {
		createActivityInteraction(input: $input) {
			id
			visibility
			content
			meta
			content_history
			attachments {
				id
				path
				meta
				created_at
				creator {
					id
					first_name
					middle_name
					last_name
				}
			}
			created_at
			creator {
				id
				first_name
				middle_name
				last_name
			}
			updated_at
			updater {
				id
				first_name
				middle_name
				last_name
			}
		}
	}
`;

export const UPDATE_ACTIVITY = gql`
	mutation Mutation($input: UpdateActivityInput!) {
		updateActivity(input: $input) {
			id
			description
			data
			status
			sub_status
			closing
			variables
			visibility
			updated_at
			updater {
				first_name
				middle_name
				last_name
				id
			}
			closer_id
			closer_form_request_id
		}
	}
`;

export const DELETE_ACTIVITY = gql`
	mutation Mutation($input: DeleteActivityInput!) {
		deleteActivity(input: $input)
	}
`;

export const ARCHIVE_ACTIVITY = gql`
	mutation Mutation($input: ArchiveActivityInput!) {
		archiveActivity(input: $input)
	}
`;

export const CLOSE_ACTIVITY = gql`
	mutation Mutation($input: CloseActivityInput!) {
		closeActivity(input: $input) {
			id
			description
			data
			status
			sub_status
			closing
			variables
			visibility
			updated_at
			updater {
				first_name
				middle_name
				last_name
				id
			}
			closer_id
			closer_form_request_id
		}
	}
`;
